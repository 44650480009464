.deal-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    width: 200px;
    height: 200px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(14%) saturate(0%) hue-rotate(322deg) brightness(101%) contrast(101%);
}

.deal-grid-item:hover {
    filter: grayscale(0);
    border-color: white;
}

.deal-grid-item img {
    width: 70%;
    height: 70%;
    object-fit: contain;
    flex-shrink: 0;
    display: flex;
}

.deal-grid-item img:hover {
    filter: grayscale(0);
}