.header-view {
    display: flex;
    flex-direction: row;
    gap: 0px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 0px;
    z-index: 1000;
    height: 100px;
    background-color: rgb(51, 194, 222);
}

.header-view img {
    display: block;
    width: 80px;
}