@font-face {
    font-family: "Inter-Bold";
    src: url("./Fonts/Inter-Bold.ttf") format('truetype');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "Inter-Medium";
    src: url("./Fonts/Inter-Medium.ttf") format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Inter-Regular";
    src: url("./Fonts/Inter-Regular.ttf") format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url("./Fonts/Inter-SemiBold.ttf") format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Neue Haas Unica-Bold';
    src: url('./Fonts/NeueHaasUnica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica-Italic';
    src: url('./Fonts/NeueHaasUnica-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica-Medium';
    src: url('./Fonts/NeueHaasUnica-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica-Regular';
    src: url('./Fonts/NeueHaasUnica-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: rgb(51, 194, 222);
}

h4 {
    font-family: Arial;
    color: rgb(0, 0, 0);
}

h1 {
    font-family: Georgia;
    font-weight: normal;
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 8px;
}