.scrollable-content {
    overflow-y: hidden;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
}

.new-modal {
    background-color: red;
}