.primary-logo-container {
    display: inline-block;
    width: 100%;
    text-align: center;
}
    
.primary-logo-container img {
    padding-right: 25px;
    width: 380px;
    object-fit: contain;
}