.socials-stack {
    display: flex;
    flex-direction: row;
    text-align: center;
    vertical-align: middle;
    gap: 20px;
}

.socials-stack:hover {
    cursor: pointer;
}