.deals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: white; */
    /* width: auto; */
}

.deals-grid-container {
    display: grid;
    gap: 40px;
    border: none;
    /* width: 100%; */
    justify-content: center;
    /* background-color: white; */
    padding: 20px;
}

@media (min-width: 750px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px 200px 200px;
    }
}

@media (min-width: 700px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px 200px 200px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px;
    }
}

@media (min-width: 450px) and (max-width: 599px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px;
    }
}

@media (min-width: 350px) and (max-width: 449px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px
    }
}

@media (min-width: 259px) and (max-width: 349px) {
    .deals-grid-container {
        grid-template-columns: 200px 200px
    }
}

@media (max-width: 260px) {
    .deals-grid-container {
        grid-template-columns: 200px;
    }
}